import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, ModuleKeywords, RiskVulnerabilityDto } from '@shared/classes';
import { RelationsService, ViewModeService } from '@shared/services';
import { RiskVulnerabilityDataService } from '../../services/data/risk-vulnerability-data.service';

@Component({
  selector: 'app-risk-vulnerability-item-form',
  templateUrl: './risk-vulnerability-item-form.component.html',
  styleUrl: './risk-vulnerability-item-form.component.scss',
})
export class RiskVulnerabilityItemFormComponent extends BaseForm<RiskVulnerabilityDto> implements OnInit {
  constructor(
    private requestService: RiskVulnerabilityDataService,
    private relationsService: RelationsService,
    public viewModeService: ViewModeService
  ) {
    super(viewModeService, ModuleKeywords.RiskVulnerability);
  }

  ngOnInit(): void {}

  getData() {
    // return this.formGroup.getRawValue();
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      applicableTo: new FormControl([]),
      description: new FormControl(null),
    });
  }
}
